<template>
  <section>
    <div class="container overflow-hidden">
      <div class="
                  col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                  pb-5
                  ps-0
                  pe-0
                  mx-auto
                  mb-4
                ">
        <div class="partnership-banner-items-container">
          <div class="row align-items-center partnership-banner-item">
            <div class="
                        col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6
                        
                      ">
              <p class="h2 text-main  partner-corparative-text">
                Korporativ müştərilər üçün
              </p>
              <p class="partner-corparative-content">
                Biz şirkətlərə sığorta risklərini effektiv idarə etməklə təminatları artırmaq
                və xərcləri optimallaşdırmağı təklif edirik.
              </p>
              <div class="partner-corporative-buttons">
                <a class="
                          btn-main
                          btn-default
                          text-decoration-none
                          btn-hover
                          d-inline-block
                          font-size-default
                          cursor-pointer
                        " @click.prevent="goToForm">
                  İndi başla
                </a>
                <router-link to="/contact" class="
                          btn-default
                          text-decoration-none
                          btn-hover
                          d-inline-block
                          font-size-default
                        ">
                  Bizimlə əlaqə
                </router-link>
              </div>
            </div>
            <div class="
                        col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6
                      ">
              <img src="@/assets/images/partner_corporativ.png" class="mx-auto partner_corp" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="corporate-services">
    <div class="container overflow-hidden">
      <div class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                pb-5
                pl-0
                pr-0
                mx-auto
                mb-4
                mt-2
                corporate-services-container
              ">
        <p class="h3 about-text">
          Bizimlə <span class="text-blue">əməkdaşlıq</span> sizə nə verəcək:
        </p>
        <div class="
                  corporate-services-items-container
                  rounded
                  py-4
                  text-start
                ">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Seçdiyiniz sığorta şirkətindən asılı olmadan bütün sığorta müqavilələrinizi bir kabinetdə görmək;
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Sığortaların zəmanətli yenilənmə xidməti;
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Sığorta şəhadətnamələrinin statusunu izləmək, rahat yenilənmə imkanı;
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Şirkətlər üçün xüsusi sığorta şərtləri əldə etmək imkanı;
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Sığortalanmalı olan nəqliyyat vasitələrinin və əməkdaşların siyahılarını rahat idarə etmək imkanı;
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="advantages-item mt-4 d-flex align-items-start">
                <img src="@/assets/images/tick-blue.svg" />
                <span class="ms-3">
                  Sığorta məsələləri üzrə tövsiyyə və sığortaların ümumi idarəedilməsinə aid praktiki yardım xidmətləri.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="partners-what-to-do">
      <div class="container overflow-hidden position-relative">
        <img src="@/assets/images/line_idea_lamp.png" class="col-md-3 lamp-box" />
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-5">
            <p class="h2 text-main fw-bolder text-center pt-2">
              Əməkdaşlığa başlamaq <br />üçün nə etməli?
            </p>
          </div>
          <div class="
                    col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                    pb-5
                    mt-5
                    mx-auto
                  ">
            <div class="row position-relative">
              <img src="@/assets/images/partner-way.svg" class="corporate_ways-bg" />
              <div class="corporative-solution-row mt-5">
                <div class="
                        col-lg-3 col-xl-3 col-md-12 col-sm-12 col-xs-12
                        corporative-solution-item
                      ">
                  <div class="text-center    mx-auto d-flex align-items-center pb-3">
                    <img src="@/assets/images/query.svg" class="mx-auto" />
                  </div>
                  <p class="text-center">
                    Əməkdaşlıq üçün müraciət göndərmək
                  </p>
                </div>
                <div class="
                        col-lg-3 col-xl-3 col-md-12 col-sm-12 col-xs-12
                        corporative-solution-item
                      ">
                  <div class="text-center    mx-auto d-flex align-items-center pb-3">
                    <img src="@/assets/images/pen.svg" class="mx-auto" />
                  </div>
                  <p class="text-center">
                    Müqaviləni bağlamaq
                  </p>
                </div>
                <div class="
                        col-lg-3 col-xl-3 col-md-12 col-sm-12 col-xs-12
                        corporative-solution-item
                      ">
                  <div class="text-center    mx-auto d-flex align-items-center pb-3">
                    <img src="@/assets/images/login_corporativ.svg" class="mx-auto" />
                  </div>
                  <p class="text-center ">
                    Kabinetə giriş əldə etmək
                  </p>
                </div>
                <div class="
                        col-lg-3 col-xl-3 col-md-12 col-sm-12 col-xs-12
                        corporative-solution-item
                      ">
                  <div class="text-center    mx-auto d-flex align-items-center pb-3">
                    <img src="@/assets/images/finger.svg" class="mx-auto" />
                  </div>
                  <p class="text-center ">
                    Təklif olunan xidmətlərdən faydalanmaq 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <request-form />
</template>

<script>
import RequestForm from "./RequestForm.vue";

export default {
  components: { RequestForm },
  methods: {
    goToForm() {
      document.getElementById("partnership-form-container").scrollIntoView();
    },
  },
};
</script>